import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo
      title='Everything Green | Contact Us'
      description='We love a good yarn, especially about our favourite medicinal herb, so let us know what you want to talk about. Get in contact today. '
    />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='Contact Us'
          subheader='We love a good yarn, especially about our favourite medicinal herb, so please get in touch and let us know what you want to talk about. We’re Sydney based so maybe we could even catch up for a quick coffee or depending on your favourite tickle, maybe a cheeky Capriosca.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
